import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {css, Theme} from "@emotion/react";

import {mb} from "@pg-design/helpers-css";
import {IRange} from "@pg-design/inputs";
import {Text} from "@pg-design/text-module";
import {FormFieldType} from "@pg-mono/form";
import {noop} from "@pg-mono/nodash";

import {IRPStore} from "../../app/rp_reducer";
import {useForm} from "../../hooks/useForm";
import {SearchFiltersRooms} from "../../search/components/filters/SearchFiltersRooms";
import {fetchOtherPropertiesInOfferOnFilterChange} from "../actions/fetch_other_properties_in_offer";
import {updateOtherPropertiesFilterAction} from "../actions/update_other_properties_filter_form";

interface IProps {
    availableRooms: number[];
    offerId: number;
    propertyId: number;
    rooms: number;
}

export interface IRoomFilterFormValues {
    rooms: IRange<number | "">;
}

export const otherPropertiesFilterFormFields = {
    rooms: FormFieldType.SelectRange
};

const options = [
    {label: "1", label_full: "1 pokój", value: 1},
    {label: "2", label_full: "2 pokoje", value: 2},
    {label: "3", label_full: "3 pokoje", value: 3},
    {label: "4", label_full: "4 pokoje", value: 4},
    {label: "5+", label_full: "5 pokoi i więcej", value: 5}
];

export const PropertyDetailOtherPropertiesFilters: React.FC<IProps> = (props) => {
    const {formData, onChange} = useForm<IRoomFilterFormValues>((store: IRPStore) => store.property.otherPropertiesInOffer, updateOtherPropertiesFilterAction);
    const dispatch = useDispatch();

    const shouldExcludeCurrentRoomsFilter = useSelector((store: IRPStore) => store.property.otherPropertiesInOffer.excludeCurrentRoomFilterOption);

    const onCheckboxChange = (name: string, value: IRange<number | "">) => {
        onChange({rooms: value});
        value && dispatch(fetchOtherPropertiesInOfferOnFilterChange(props.offerId, props.propertyId, value));
    };

    const availableRooms = shouldExcludeCurrentRoomsFilter ? props.availableRooms.filter((rooms) => rooms !== props.rooms) : props.availableRooms;

    return (
        <>
            <Text as="p" css={roomLabel} variant="body_copy_1">
                Liczba pokoi
            </Text>
            <SearchFiltersRooms
                toggleDropdown={noop}
                clearField={noop}
                showFooter={false}
                name="other-properties-rooms-filters"
                value={formData.formValues.rooms}
                onChange={onCheckboxChange}
                onAfterChange={noop}
                roomOptions={options}
                rooms={availableRooms}
            />
        </>
    );
};

const roomLabel = (theme: Theme) => css`
    ${mb(0.5)};

    @media (min-width: ${theme.breakpoints.md}) {
        display: none;
    }
`;
